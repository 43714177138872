import { useEffect, useState } from "react";
import HorizontalScroll from "./components/Slider/Slider.js";
import styled from "styled-components";

const AppContainer = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  overflow: hidden;
  height: ${(props) => props.device === "iOS" ? "-moz-available" : "100vh"};
  height: ${(props) => props.device === "iOS" ? "-webkit-fill-available" : "100vh"};
  height: ${(props) => props.device === "iOS" ? "fill-available" : "100vh"};
  min-height: ${(props) => props.device === "iOS" ? "-webkit-fill-available" : "none"};
  max-height: ${(props) => props.device === "iOS" ? "-webkit-fill-available" : "none"};
`;

function App() {
  const [mobileOS, setMobileOS] = useState(null);

  useEffect(() => {
    setMobileOS(getMobileOperatingSystem())
  }, [])

  function getMobileOperatingSystem() {
    const userAgent = navigator.userAgent || navigator.vendor ||window.opera;

    // Windows Phone must come first because its UA also contains "Android"
    if (/windows phone/i.test(userAgent)) {
        return "Windows Phone";
    }
    if (/android/i.test(userAgent)) {
        return "Android";
    }
    // iOS detection from: http://stackoverflow.com/a/9039885/177710
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        return "iOS";
    }
    return "unknown";
  }

  return (
    <AppContainer className="APP" device={mobileOS}>
      <HorizontalScroll/>
    </AppContainer>
  );
}

export default App;
