import styled, { keyframes } from "styled-components";
import Slide from "../Slide/Slide.js";

const isMobile = window.innerWidth < 768;
const slideWidthPercentage = isMobile ? 100 : 33; // Each slide width is 100% for mobile and 33% for web
const multiplier = isMobile ? 5 : 2;

// Define keyframes for horizontal scrolling animation
const scrollAnimation = keyframes`
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(-${slideWidthPercentage * multiplier}%);
  }
`;

const animationDuration = isMobile ? 30 : 15

const SliderContainer = styled.div`
  height: 100%;
  display: flex;
  white-space: nowrap; /* Prevent wrapping of slides */
  animation: ${scrollAnimation} ${animationDuration}s linear infinite alternate; /* Apply CSS animation */
`;

const SlideWrapper = styled.div`
  width: ${slideWidthPercentage}%; /* Set width for each slide */
`;

const HorizontalScroll = () => {
  return (
    <div className="scroll-container" style={{ overflowX: 'hidden', whiteSpace: 'nowrap' }}>
      <SliderContainer>
        <SlideWrapper>
          <Slide src={'images/0.webp'} language={'catalan'}/>
        </SlideWrapper>
        <SlideWrapper>
          <Slide src={'images/1.webp'} language={'spanish'}/>
        </SlideWrapper>
        <SlideWrapper>
          <Slide src={'images/2.webp'} language={'english'}/>
        </SlideWrapper>
        <SlideWrapper>
          <Slide src={'images/3.webp'} language={'catalan'}/>
        </SlideWrapper>
        <SlideWrapper>
          <Slide src={'images/4.webp'} language={'spanish'}/>
        </SlideWrapper>
        <SlideWrapper>
          <Slide src={'images/5.webp'} language={'english'}/>
        </SlideWrapper>
      </SliderContainer>
    </div>
  );
};

export default HorizontalScroll;
